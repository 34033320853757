.dynamic-columns {
  display: flex;
  align-items: baseline;
  flex-direction: row;
}
.hide-on-phone {
  display: block !important;
}

.hide-on-big-screen {
  display: none;
}
@media screen and (max-width: 650px) {
  .dynamic-columns {
    flex-direction: column;
  }
  .hide-on-big-screen {
    display: block;
  }
  .hide-on-phone {
    display: none !important;
  }
}
iframe {
  display: none;
}
.swap-card-text {
  background: #ffffff12;
  padding: 8px 16px 8px 16px;
  border-radius: 20px;
  font-family: Inter;
  font-size: 15.25px;
  font-weight: 500;
  line-height: 18.46px;
  text-align: left;
  color: #ffffff;
}
.encryption-div {
  background: #00000029;
  border: 1px solid #757575;
  padding: 10px 18px 10px 18px;
  border-radius: 10px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 13.07px;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-conatiner {
  box-shadow: 0px 4px 15.9px -10px #00000040;
  background: #1a1a1a;
  border-radius: 23px;
  padding: 20px;
  margin-left: 20px;
  max-width: 500px;
}
.number-conatainer {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #000000;
  font-size: 12.8px;
  font-weight: 600;
  margin-right: 15px;
}
.row-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.link-button {
  padding: 7px 17px 7px 17px;
  border-radius: 23px;
  background: #000000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
}
.swap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title-text {
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 40.32px;
  text-align: center;
  color: #ffffff;
}
.swap-section {
  background: #252525;
  border: 1px solid #2e2e2e;
  border-radius: 18px;
  display: flex;
  box-shadow: 0px 0px 6.2px -3px #00000040 inset, 0px 0px 0px 2px #ffffff00;
}
.swap-header-seciton {
  background: #252525;
  border: 1px solid #2e2e2e;
  border-radius: 18px;
  position: relative;
  display: flex;
  box-shadow: 0px 0px 6.2px -3px #00000040 inset, 0px 0px 0px 2px #ffffff00;
}
.sub-title-text {
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color:#FFFFFF
}
.galaxe-container {
  cursor: pointer;
  border: 1px solid #2E2E2E;
  box-shadow: 0px 0px 63.5px 2px #49494940;
  background: #FFFFFF2B;
  border-radius: 28px;
  padding: 10px;
  max-width: 550px;
  width: 100%;
}
.arrow-container {
  background: #FFFFFF;
  border-radius: 19px;
  padding: 10px;
  height: 64px;
  width: 64px;
}